import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 654.000000 652.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,652.000000) scale(0.100000,-0.100000)">
          <path d="M3786 5034 c-153 -37 -308 -140 -759 -503 -297 -240 -310 -251 -298
-251 11 0 426 209 826 415 206 106 272 128 400 128 111 1 166 -22 228 -94 53
-62 65 -96 66 -190 1 -90 -8 -146 -56 -341 l-36 -147 74 -85 c41 -46 79 -85
84 -85 10 -1 75 248 115 439 24 115 26 269 5 359 -43 182 -191 329 -364 361
-75 14 -215 11 -285 -6z"/>
          <path d="M2615 5034 c-11 -2 -45 -9 -75 -15 -118 -24 -246 -111 -304 -206 -52
-85 -68 -142 -73 -258 -12 -254 54 -372 571 -1019 66 -84 187 -226 191 -226 3
0 -1 13 -7 28 -28 64 -443 880 -464 913 -60 95 -89 282 -60 383 25 83 108 167
191 191 85 25 224 7 495 -64 83 -23 69 -27 155 51 22 20 52 46 68 58 15 12 27
25 27 30 0 17 -367 104 -532 125 -111 15 -144 16 -183 9z"/>
          <path d="M3680 4467 c0 -2 83 -167 184 -368 101 -200 217 -431 258 -514 88
-175 114 -263 105 -365 -10 -114 -79 -215 -176 -256 -74 -31 -253 -12 -499 52
-94 25 -101 25 -132 -6 -3 -3 -35 -31 -72 -63 -38 -32 -68 -62 -68 -66 0 -14
443 -117 567 -131 101 -11 129 -9 228 12 229 50 375 244 375 498 0 146 -36
247 -146 416 -47 71 -297 410 -313 424 -3 3 -42 50 -87 105 -202 252 -224 278
-224 262z"/>
          <path d="M3213 4061 c-58 -27 -93 -82 -93 -147 0 -157 200 -225 295 -100 104
137 -46 320 -202 247z"/>
          <path d="M2286 3900 c-13 -37 -77 -297 -100 -411 -92 -449 76 -732 449 -756
193 -12 339 51 637 274 59 44 390 306 414 328 7 5 52 44 102 85 50 41 89 76
87 78 -4 5 -664 -326 -840 -421 -152 -82 -208 -102 -318 -118 -70 -10 -92 -9
-147 5 -78 20 -120 51 -168 123 -34 52 -37 62 -40 143 -4 92 16 213 65 403
l25 99 -24 26 c-26 28 -69 77 -111 127 -20 23 -27 27 -31 15z"/>
          <path d="M2015 2391 c-4 -8 5 -16 24 -24 21 -8 31 -18 31 -33 0 -25 -147 -372
-168 -396 -8 -10 -23 -18 -34 -18 -10 0 -18 -7 -18 -15 0 -13 15 -15 92 -13
98 3 130 20 58 32 -47 7 -49 19 -20 84 l19 42 99 0 99 0 19 -56 c20 -59 14
-73 -28 -74 -10 0 -18 -7 -18 -15 0 -13 16 -15 102 -13 64 2 103 7 105 14 2 7
-8 14 -21 18 -31 8 -46 35 -120 223 -62 159 -88 214 -109 231 -21 18 -103 27
-112 13z m126 -206 c20 -52 34 -97 31 -100 -7 -7 -147 -6 -154 1 -4 3 7 38 24
77 16 40 34 82 39 95 4 12 12 22 16 22 5 0 24 -43 44 -95z"/>
          <path d="M2948 2394 c-11 -11 11 -34 33 -34 33 0 39 -34 39 -217 0 -190 -7
-223 -51 -223 -10 0 -19 -7 -19 -15 0 -12 16 -15 85 -15 67 0 85 3 85 14 0 8
-13 17 -29 21 -18 4 -31 14 -35 28 -3 12 -5 96 -5 187 l0 165 32 -66 c18 -36
47 -102 66 -145 80 -185 91 -206 103 -202 7 3 49 93 92 201 44 108 82 197 85
197 3 0 4 -80 1 -177 l-5 -178 -32 -10 c-18 -5 -33 -15 -33 -22 0 -10 27 -13
110 -13 87 0 110 3 110 14 0 7 -13 16 -29 19 -18 4 -31 14 -35 29 -3 13 -6
105 -6 205 0 193 5 213 51 213 10 0 19 7 19 15 0 13 -13 15 -76 13 l-75 -3
-75 -185 c-40 -102 -76 -189 -78 -195 -3 -10 -23 29 -113 230 -31 67 -60 117
-76 129 -27 20 -126 34 -139 20z"/>
          <path d="M3807 2379 c-157 -83 -197 -291 -82 -427 44 -52 99 -76 180 -76 80
-1 128 20 182 76 73 76 97 213 53 304 -27 55 -98 120 -146 133 -62 17 -144 13
-187 -10z m199 -50 c95 -92 80 -343 -25 -403 -48 -27 -113 -24 -155 8 -55 42
-71 86 -71 201 0 88 3 107 25 150 33 63 75 88 143 82 38 -3 55 -11 83 -38z"/>
          <path d="M4240 2388 c0 -8 15 -21 33 -29 l32 -14 3 -185 c1 -102 0 -195 -3
-207 -3 -14 -16 -24 -35 -28 -16 -4 -30 -13 -30 -21 0 -11 18 -14 84 -14 93 0
115 12 63 34 -33 13 -34 15 -40 91 -10 116 -9 277 2 273 6 -1 69 -82 141 -178
159 -211 166 -220 185 -220 13 0 15 30 15 216 0 129 4 223 10 235 6 11 21 19
35 19 16 0 25 6 25 15 0 12 -16 15 -85 15 -69 0 -85 -3 -85 -15 0 -8 8 -15 19
-15 10 0 23 -6 29 -12 14 -18 28 -308 15 -308 -10 0 -69 75 -206 256 -59 79
-81 94 -151 101 -45 5 -56 3 -56 -9z"/>
          <path d="M4795 2389 c-5 -7 -4 -14 1 -16 71 -24 95 -54 167 -208 30 -66 37
-92 37 -143 0 -64 -20 -102 -55 -102 -8 0 -15 -7 -15 -15 0 -12 18 -15 105
-15 83 0 105 3 105 14 0 8 -11 17 -25 20 -37 9 -48 40 -43 115 4 49 13 81 37
125 18 32 44 81 58 108 29 56 65 88 99 88 15 0 24 6 24 15 0 12 -17 15 -95 15
-78 0 -95 -3 -95 -15 0 -9 9 -15 24 -15 14 0 27 -4 30 -9 6 -9 -84 -201 -94
-201 -3 0 -28 45 -55 100 -36 74 -57 106 -80 120 -42 26 -119 37 -130 19z"/>
          <path d="M1275 2380 c-3 -6 2 -13 12 -16 56 -17 62 -38 62 -224 1 -176 -4
-199 -46 -216 -19 -8 -31 -19 -28 -24 3 -6 53 -10 111 -10 86 0 104 3 104 15
0 8 -8 15 -18 15 -40 0 -53 32 -50 121 l3 84 123 3 123 3 -3 -98 -3 -98 -32
-10 c-18 -5 -33 -15 -33 -22 0 -10 27 -13 110 -13 91 0 110 3 110 15 0 8 -9
15 -19 15 -46 0 -51 19 -51 216 0 109 4 193 10 205 6 11 21 19 35 19 16 0 25
6 25 15 0 12 -19 15 -110 15 -91 0 -110 -3 -110 -15 0 -9 9 -15 23 -15 39 0
47 -18 47 -112 l0 -88 -125 0 -125 0 0 81 c0 92 10 119 45 119 16 0 25 6 25
15 0 12 -18 15 -104 15 -60 0 -108 -4 -111 -10z"/>
          <path d="M2410 2375 c0 -9 9 -15 24 -15 44 0 47 -15 44 -226 l-3 -199 -32 -10
c-18 -5 -33 -15 -33 -22 0 -10 27 -13 110 -13 83 0 110 3 110 13 0 7 -15 17
-32 22 l-33 10 -3 88 -3 88 33 -3 c29 -3 40 -13 90 -88 69 -103 105 -130 170
-130 59 0 70 16 25 35 -37 15 -84 67 -136 149 l-31 50 43 24 c107 58 106 177
-1 223 -59 26 -342 29 -342 4z m306 -46 c33 -33 41 -87 20 -131 -21 -44 -51
-58 -121 -58 l-55 0 0 98 c0 65 4 102 13 111 21 22 114 9 143 -20z"/>
          <path d="M2340 1550 c0 -16 5 -30 10 -30 6 0 10 9 10 19 0 20 32 37 50 26 5
-3 10 -45 10 -92 0 -70 -3 -88 -17 -99 -16 -12 -12 -13 32 -13 39 0 46 2 33
10 -15 8 -18 25 -18 104 0 89 1 95 20 95 12 0 25 -10 32 -25 14 -31 32 -33 24
-2 -3 12 -6 25 -6 30 0 4 -40 7 -90 7 l-90 0 0 -30z"/>
          <path d="M2726 1559 c-50 -40 -51 -133 -1 -176 18 -16 39 -23 68 -23 45 1 63
11 90 50 32 46 13 135 -32 160 -33 17 -96 12 -125 -11z m100 3 c39 -25 46
-124 13 -166 -58 -74 -142 18 -109 119 16 47 62 70 96 47z"/>
          <path d="M3083 1566 c14 -11 17 -29 17 -96 0 -67 -3 -85 -17 -96 -16 -12 -12
-13 32 -13 44 0 48 1 33 13 -11 8 -18 26 -18 45 0 29 2 31 38 31 58 0 101 56
74 96 -15 23 -53 34 -114 34 -55 -1 -61 -2 -45 -14z m101 -6 c21 -8 26 -16 26
-44 0 -37 -20 -56 -59 -56 -18 0 -21 6 -21 49 0 30 5 51 13 54 6 3 13 6 14 6
1 1 13 -3 27 -9z"/>
          <path d="M3622 1568 c12 -10 17 -34 20 -90 4 -94 21 -118 83 -118 66 0 85 28
85 123 0 62 3 78 18 86 13 8 7 10 -28 10 -40 0 -43 -1 -27 -13 13 -10 17 -29
17 -88 0 -87 -9 -101 -67 -96 l-38 3 -3 83 c-2 53 1 89 9 98 10 12 3 14 -37
14 -40 0 -45 -2 -32 -12z"/>
          <path d="M4013 1567 c14 -12 17 -30 17 -97 0 -67 -3 -85 -17 -96 -16 -12 -12
-13 32 -13 44 0 48 1 33 13 -11 8 -18 26 -18 45 0 30 2 31 40 31 65 0 101 57
66 105 -11 16 -29 20 -93 23 -68 2 -76 1 -60 -11z m101 -7 c21 -8 26 -16 26
-44 0 -37 -20 -56 -59 -56 -18 0 -21 6 -21 48 0 65 6 71 54 52z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
